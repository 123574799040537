import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
// import Card from 'react-bootstrap/Card';

const CustomVsOffShelf = () => {
  return (
    <div className=' mxr__section '>
      <Container>
        <h3
          className='mxr__section-title textUppercase'
          style={{ textAlign: 'center' }}
        >
          Custom vs. Off-The-Shelf Solutions
        </h3>

        <Row className='mt-5'>
          <Col>
            <div className='vscardsVR360 VsBottom'>
              <h5 className='textUppercase'>Custom 360 Virtual Tour Content</h5>
              <p style={{ textAlign: 'start' }}>
                Tailored to specific needs, our custom solutions ensure greater
                relevance and engagement, integrating seamlessly with your
                systems.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Tailored to your needs</li>
                <li>Highly engaging</li>
                <li>Flexible and updatable</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className='vscardsVR360'>
              <h5 className='textUppercase'>
                Off-The-Shelf 360 Virtual Tour Content
              </h5>
              <p style={{ textAlign: 'start' }}>
                Ready-made solutions for quick deployment, ideal for general
                business needs.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Cost-effective</li>
                <li>Quick deployment</li>
                <li>Ideal for general use</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default CustomVsOffShelf;

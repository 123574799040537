import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import step1 from './../../../assets/images/DinoWorld/dino-playstore1.png';
import step2 from './../../../assets/images/DinoWorld/dino-front1.png';
import step3 from './../../../assets/images/DinoWorld/dino-step3.png';
import app1 from './../../../assets/images/DinoWorld/GoogleStore.png';
import app2 from './../../../assets/images/DinoWorld/appStore.png';
const HowItHappens = () => {
  return (
    <div className='mxr__capabilities mxr__section'>
      <Container>
        <h3 className='mxr__section-title_black text-center mb-5'>
          HOW YOU CAN GET THE APP
        </h3>
        <Row>
          <Col className='text-center'>
            <img width='300px' src={step1} alt='step1' />
            <h5 className='get_app'>1. Download the DinoWorld App</h5>
            <p>Download the DinoWorld App from Playstore or Appstore.</p>
          </Col>
          <Col className='text-center'>
            <img width='300px' src={step2} alt='step1' />
            <h5 className='get_app'>2. Register and Activate your Product</h5>
            <p>
              Login and activate the product you have purchased or just enjoy
              the free content
            </p>
          </Col>
          <Col className='text-center'>
            <img width='300px' src={step3} alt='step1' />
            <h5 className='get_app'>3. Scan and Downlaod</h5>
            <p>Scan the book and enjoy.</p>
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <img src={app1} alt='dsf' />
            <img src={app2} alt='dsf' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HowItHappens;

import React from 'react';
import { Helmet } from 'react-helmet';
import { Innerbanner, Readytotalk } from '../../components';
// import About from '../vr360/About';
// import images from '../../assets/icons/benifits/ri.png';
import VsCards from './vsCards';
import Text from './Text';
import Custom from './custom';

const bannerdata = {
  img: 'E-learning.png',
  title: 'Welcome to Our E-Learning Platform.',
  text: 'Empowering you to learn anytime, anywhere',
};

const readytotalk = {
  img: 'MR.jpg',
  title: 'Contact Us',
  text: 'Partner with MXR to create custom AI solutions that integrate seamlessly with your educational tools. Enhance personalised learning, automate tasks, and gain valuable insights. Start revolutionizing your e-learning experience today with MXR!',
  button: 'Get in Touch',
};

function ELearning() {
  return (
    <>
      <Helmet>
        <title>E-Learning | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <Text />
      {/* <About banner={dataList} /> */}
      <Custom />
      <VsCards />
      <Readytotalk discuss={readytotalk} />
    </>
  );
}

export default ELearning;

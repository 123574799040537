import React from 'react';
import { Innerbanner, Process, Readytotalk } from '../../components';
import Landscape from './Landscape';
// import Benifits from '../vr360/Benifits';
// import images from '../../assets/All-Images/research-and-innovation.png';
import { Helmet } from 'react-helmet';
import About from './About';
import How from './How';

const bannerdata = {
  img: 'Research-and-Innovation.jpg',
  // img: 'r&d.jpg',
  title: 'R&D AND INNOVATION',
  text: 'we will provide you the right kind of R&D service which will act as a fuel to kick start your new business objective.',
};

const readytotalk = {
  img: 'python.webp',
  title: 'READY TO DISCUSS YOUR PROJECT? ',
  text: 'We would love to hear about your idea and make it happen. You come to us with your idea, and we do all of the planning and costing for you.',
  button: 'CONTACT US',
};

const RI = () => {
  return (
    <div>
      <Helmet>
        <title>Research And Development | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <About />
      <Landscape />
      <How />
      <Process title='OUR SOFTWARE DEVELOPMENT ' />
      <Readytotalk discuss={readytotalk} />
    </div>
  );
};

export default RI;

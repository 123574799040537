import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import styled from 'styled-components';
import { Link as ScrollLink } from 'react-scroll';

const Outerdiv = styled.div`
  background-image: url('assets/images/Innerbanner/${(props) =>
    props.$banner}');
`;

const Banner = (props) => {
  return (
    <div className='internal_page_banner'>
      <Outerdiv $banner={props.banner.img}>
        <Container>
          <Row className='justify-content-md-center'>
            <Col sm={9}>
              <h1>{props.banner.title}</h1>
            </Col>
          </Row>
          <p sm={9}>{props.banner.text}</p>
          <div className='text-center'>
            <ScrollLink
              to='readytotalk'
              smooth={true}
              duration={400}
              className='btn btn-primary siteBtn'
            >
              Get Demo
            </ScrollLink>
          </div>
        </Container>
      </Outerdiv>
    </div>
  );
};

export default Banner;

/*{' '}
              {props.banner.button && (
                <>
                  <ScrollLink
                    to='readytotalk'
                    smooth={true}
                    duration={500}
                    className='mx-10 mxr__section-btn-white'
                  >
                    {props.banner.button}
                  </ScrollLink>
                  <a className='mx-10 mxr__section-btn-white'>Get In Touch</a>
                </>
              )}{' '}
              */

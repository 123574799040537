import React from 'react';
import './banner.css';
import { Carousel } from 'react-bootstrap-v5';
// import img1 from '../../assets/icons/banner/img1.jpg';
// import img2 from '../../assets/icons/banner/img2.jpg';
// import video from '../../assets/icons/banner/video.mov';
// import { Link } from 'react-router-dom';
// import imgnew from '../../assets/icons/banner/imgnew.png';
import video from '../../assets/icons/banner/HomePage2.mp4';
import { Link as ScrollLink } from 'react-scroll';

function Banner() {
  return (
    <div>
      <Carousel fade>
        <Carousel.Item interval={1000}>
          <video
            className='respoHomeVideo'
            src={video}
            autoPlay
            muted
            loop
            poster={require(`../../assets/icons/banner/bannerThumbnail/home.jpg`)}
          />
          <Carousel.Caption>
            <div className='HomeTitle'>
              <h3 className='mxr__section-title '>
                YOUR TECHNOLOGY PARTNER IN INNOVATION
              </h3>
              <p className='mb-3'>
                Enhancing Training and Engagement through AR, VR, and Mixed
                Reality
              </p>{' '}
              <br />
              <ScrollLink
                to='features-section'
                smooth={true}
                duration={300}
                className='mxr__section-btn discoverbtn'
              >
                Discover Our Solutions
              </ScrollLink>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        {/* <Carousel.Item interval={1000}>
          <img className='d-block w-100' src={imgnew} alt='First slide' />
          <Carousel.Caption>
            <h3 className='mxr__section-title'>
              Your Technology Partner In Innovation
            </h3>
            <span className='mb-3 titleSpan'>
              Enhancing Training and Engagement through AR, VR, and Mixed
              Reality
            </span>
            <br />
            <Link
              to=''
              className='mxr__section-btn discoverbtn'
              onClick={handleScroll}
            >
              Discover Our Solutions
            </Link>{' '}
          </Carousel.Caption>
        </Carousel.Item> */}

        {/* /////////////////////////////////////// */}
        {/* <Carousel.Item interval={100}>
          <img className='d-block w-100' src={img1} alt='First slide' />
          <Carousel.Caption>
            <h3 className='mxr__section-title'>
              EMPLOY THE SHEER POWER OF MIXED REALITY WITH MXR
            </h3>
            <Link to='/contact' className='mxr__section-btn discoverbtn'>
              CONTACT US
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
     

        
        <Carousel.Item interval={1000}>
          <img className='d-block w-100' src={img2} alt='Second slide' />
          <Carousel.Caption>
            <h3 className='mxr__section-title'>
              SCALE YOUR DEVELOPMENT WITH META EXTENDED REALITY (MXR)
            </h3>
            <Link to='/contact' className='mxr__section-btn discoverbtn'>
              CONTACT US
            </Link>
          </Carousel.Caption>
        </Carousel.Item> */}
        {/* ////////////////////////////// */}
      </Carousel>
    </div>
  );
}

export default Banner;

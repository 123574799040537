import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
import './innerbanner.css';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
// import vrbanner from '../../assets/images/Innerbanner/vr-banner2.jpg';
const Outerdiv = styled.div`
  background-image: url('/assets/images/Innerbanner/${(props) =>
    props.$banner}');
`;

const Innerbanner = (props) => {
  return (
    <div className='internal_page_banner'>
      <Outerdiv $banner={props.banner.img}>
        <Container>
          <Row className='justify-content-md-center'>
            <Col sm={9} className=''>
              <h1>{props.banner.title}</h1>
              {props.banner.button && (
                <>
                  <Link className='mx-10 mxr__section-btn-white'>
                    {props.banner.button}
                  </Link>
                  <Link className='mx-10 mxr__section-btn-white'>
                    Get In Touch
                  </Link>
                </>
              )}
            </Col>
          </Row>
          <p sm={9}>{props.banner.text}</p>
        </Container>
      </Outerdiv>
    </div>
  );
};

export default Innerbanner;

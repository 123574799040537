import React from 'react';
import Readytotalk from '../../components/readytotalk/Readytotalk';
import Innerbanner from '../../components/innerbanner/Innerbanner';
// import Values from '../Values';
import WhoWeAre from './WhoWeAre';
import WhyPartnerUs from '../../components/WhyPartnerUS/index';
import SecretSauce from './SecretSauce';
import Vision from './vision/Vision';
import HireUs from './hireus/HireUs';
// import Process from '../../components/process/Process';
import TimeLine from './timeline/TimeLine';
import './about.css';
import { Helmet } from 'react-helmet';

const bannerdata = {
  img: 'About3.png',
  title: 'Your Technology Partner for Innovation and Excellence',
  text: 'Custom MXR experiences to empower your business with a new dimension of virtual reality, effectively used in multiple industries',
};

const readytotalk = {
  img: 'about-banner.jpg',
  title: 'Get in Touch',
  text: 'Ready to innovate and create? Partner with MXR today to explore how our cutting-edge solutions can transform your business or educational institution.',
  button: 'Contact',
};
const About = () => {
  return (
    <div>
      <Helmet>
        <title>About Us | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <Vision />
      <WhoWeAre />
      <SecretSauce />
      <WhyPartnerUs />
      <TimeLine />
      <HireUs />
      {/* <Values /> */}
      {/* <Process title='WE SIMPLIFY SOFTWARE DEVELOPMENT PROCESS' /> */}
      <Readytotalk discuss={readytotalk} />
    </div>
  );
};

export default About;

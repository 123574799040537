import React from 'react';
import { Card, Container } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom';
import case1 from '../../assets/images/Chat-Bot2.jpg';
// import case2 from '../../assets/images/Partnering.jpg';
import case3 from '../../assets/images/NHS2.jpg';
function AllCaseStudies() {
  return (
    <div className='mxr__blog-post-list'>
      <Container>
        <h2 className='mxr__section-title text-center'>case studies</h2>
        <div className='row align-items-center text-center my-5'>
          <div className='col-lg-4'>
            <Card className='mb-5'>
              <img src={case1} alt='Case Study 1' />
              <div className='blog_content_info'>
                <div className='heading_para'>
                  <h4 className='font-weight-light'>
                    Enhancing the Learner Journey with Chat Bot{' '}
                  </h4>
                  {/* <span>January 1, 2024</span> */}
                </div>
                <p>
                  The APM Project Management Qualification (PMQ) is one of the
                  most challenging...
                </p>
                <Link to='/case-study/ai-chat-bot'>
                  <button
                    className='mxr__section-btn'
                    style={{ width: '100%', padding: '10px' }}
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </Card>
          </div>

          {/* <div className='col-lg-4'>
            <Card className='mb-5'>
              <img src={case2} alt='Case Study 2' />
              <div className='blog_content_info'>
                <div className='heading_para'>
                  <h4 className='font-weight-light'>
                    Partnering with a Leading UK College
                  </h4>
                </div>
                <p>
                  Develop Immersive Content for T Level Health and Science
                  Programmes...
                </p>
                <Link to='/ImmersiveCase-Study'>
                  <button
                    className='mxr__section-btn'
                    style={{ width: '100%', padding: '10px' }}
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </Card>
          </div> */}

          <div className='col-lg-4'>
            <Card className='mb-5'>
              <img src={case3} alt='Case Study 3' />
              <div className='blog_content_info'>
                <div className='heading_para'>
                  <h4 className='font-weight-light'> NHS VR Walkthrough</h4>
                  {/* <span>March 15, 2024</span> */}
                </div>
                <p>
                  The NHS sought an innovative way to engage young people...
                </p>
                <Link to='/NHS-Case-study'>
                  <button
                    className='mxr__section-btn'
                    style={{ width: '100%', padding: '10px' }}
                  >
                    Read More
                  </button>
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AllCaseStudies;

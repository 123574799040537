import React from 'react';
import { Helmet } from 'react-helmet';
import { Innerbanner, Readytotalk } from '../../components';
// import room from '../../assets/images/immersive2.png';
// import About from './About';
// import Offer from './Offer';
// import Why from './Why';
// import Applications from './Applications';
import Revolution from './Revolution';
import Whatis from './Whatis';
import Expertise from './Expertise';
import Partner from './Partner';
import './immersive.css';

// const dataList = [
//   {
//     id: '1',
//     heading: 'Immersive Rooms',
//     images: <img src={room} alt='lady with vr set' />,
//     pharagraphs: [
//       'At MXR, we specialise in custom-building immersive rooms for colleges, training centres, and businesses. Our immersive rooms leverage advanced technology to create engaging and interactive environments that enhance learning and collaboration.',
//     ],
//   },
// ];

// const readytotalk = {
//   img: 'MR.jpg',
//   title: 'Contact Us',
//   text: 'Partner with MXR to develop custom AI solutions that integrate seamlessly into your immersive environments. Enhance user experiences, optimise interactions, and unlock new possibilities with cutting-edge technology. Transform your immersive rooms today with MXR!',
//   button: 'Get in Touch',
// };

const bannerdata = {
  img: 'immersive-workspaces.jpg',
  title: 'Immersive Workspaces and Rooms',
  text: 'Transform Your Training Centres and Workspaces with Immersive Technology',
};

const readytotalk = {
  img: 'MR.jpg',
  title: 'Book a Demo',
  text: 'Ready to transform your learning environment? Contact us today to book a demo of our immersive room solutions and see how MXR can enhance your educational and training programmes.',
  button: 'Get in Touch',
};
function Platform() {
  return (
    <div>
      <Helmet>
        <title>Immersive Rooms | MXR</title>
        <meta
          name='description'
          content='360° Virtual Reality (VR) Tours became a perfect marketing tool in real estate and design. It is particularly useful for those projects that are still in the development stage or under construction.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <Revolution />
      <Whatis />
      <Expertise />
      {/* <About banner={dataList} /> */}
      {/* <Offer /> */}
      {/* <Why /> */}
      {/* <Applications /> */}
      <Partner />

      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default Platform;

import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
// import Card from 'react-bootstrap/Card';

const Compare = () => {
  return (
    <div className=' mxr__section '>
      <Container>
        <h3
          className='mxr__section-title textUppercase'
          style={{ textAlign: 'center' }}
        >
          Custom vs. Off-The-Shelf Solutions
        </h3>

        <Row className='mt-5'>
          <Col>
            <div className='vscards VsBottom'>
              <h5 className='textUppercase'>Custom Platform Development</h5>
              <p style={{ textAlign: 'start' }}>
                Custom platform development allows organisations to tailor
                systems to their specific needs, providing greater control and
                relevance. Our custom platforms are designed to integrate
                seamlessly with your existing infrastructure, ensuring a
                cohesive and efficient operation.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Tailored to specific business needs</li>
                <li>Highly engaging and relevant</li>
                <li>Flexible and scalable</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className='vscards'>
              <h5 className='textUppercase'>Off-The-Shelf Solutions</h5>
              <p style={{ textAlign: 'start' }}>
                Off-the-shelf solutions offer ready-made platforms that can
                quickly be deployed to meet general business needs. These
                platforms are cost-effective and convenient, providing essential
                functionalities without the need for extensive customisation.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Cost-effective and ready to use</li>
                <li>Ideal for general business needs</li>
                <li>Quick deployment</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Compare;

import React from 'react';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import {
  FaUserCheck,
  FaUserShield,
  FaTrophy,
  FaUser,
  FaBalanceScale,
  // FaOptinMonster,
  FaEquals,
} from 'react-icons/fa';

import { MdIntegrationInstructions } from 'react-icons/md';
import './timeline.css';
import { Container, Row, Col } from 'react-bootstrap-v5';
import timeline from '../../../assets/images/manwithVR2.png';
// import { Link } from 'react-router-dom';

const TimeLine = () => {
  return (
    <div className='mxr__timeline mxr__section'>
      <Container>
        <Row>
          <Col lg={6} sm={12} className='text-left'>
            <h3 className='mxr__section-title textUppercase '>Our Values</h3>
            <VerticalTimeline>
              <VerticalTimelineElement
                className='vertical-timeline-element--work mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <FaUserCheck />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>Innovation</h4>
                  <p className='textLeft'>
                    We constantly push the boundaries of technology to deliver
                    creative and impactful solutions tailored to your needs.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <FaUserShield />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>Collaboration</h4>
                  <p className='textLeft'>
                    We partner with you to understand your unique challenges and
                    deliver solutions that set you up for success.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <FaTrophy />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>Excellence</h4>
                  <p className='textLeft'>
                    We are committed to delivering the highest standards in
                    every project, ensuring quality and effectiveness.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work last mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <MdIntegrationInstructions />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>Empowerment</h4>
                  <p className='textLeft'>
                    We empower your organisation to leverage the latest
                    technology, helping you achieve your goals and drive
                    success.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work last mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <FaUser />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>Integrity</h4>
                  <p className='textLeft'>
                    We build trust through transparency, honesty, and a
                    client-first approach in everything we do.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work last mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <FaBalanceScale />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>Sustainability</h4>
                  <p className='textLeft'>
                    We are dedicated to incorporating sustainable practices into
                    our projects, ensuring that our solutions not only serve
                    your goals but also contribute to a greener future.
                  </p>
                </div>
              </VerticalTimelineElement>
              <VerticalTimelineElement
                className='vertical-timeline-element--work last mb-3'
                contentStyle={{ background: '#fff' }}
                iconStyle={{ background: '#fff', color: '#1292ee' }}
              >
                <div className='time_line-icon'>
                  <FaEquals />
                </div>
                <div className='time_line-icon_text'>
                  <h4 className='textLeft'>
                    Diversity, Equality, and Inclusion (EDI)
                  </h4>
                  <p className='textLeft'>
                    We are committed to fostering a culture of diversity,
                    equality, and inclusion, ensuring that every voice is heard,
                    respected, and valued in both our workplace and the
                    solutions we deliver.
                  </p>
                </div>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Col>
          <Col sm={12} lg={6} className='timeline-right-image'>
            <img src={timeline} alt='dfgdfg' />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TimeLine;

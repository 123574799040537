import React from 'react';
import './immersivecase.css';
import { Container, Row, Col } from 'react-bootstrap-v5';
import { Innerbanner } from '../../components';
import Readytotalk from '../../components/readytotalk/Readytotalk';
import imm from '../../assets/images/Immersive-rooms3.png';

import {
  FaHandsHelping,
  FaUserGraduate,
  FaCogs,
  FaUsers,
} from 'react-icons/fa';

const readytotalk = {
  img: 'MR.jpg',
  title: 'Get in Touch',
  text: 'Contact us today to learn more about how MXR can partner with your institution to revolutionise the way you deliver education.',
  button: 'Contact us',
};
function ImmCase() {
  const bannerdata = {
    img: 'immersiveUk2.jpg',
    title:
      ' Partnering with a Leading UK College to Develop Immersive Content ',
    text: '',
  };
  return (
    <>
      <Innerbanner banner={bannerdata} />
      <Container>
        <div className='gutters'>
          {/* <header className="page-header group">
            <div className="logo">
              <h1>James L Cobb</h1>
              <h2>User Experience Designer</h2>
            </div>
            <nav className="main-nav">
              <ul>
                <li className="active">
                  <a href="#">Work</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li>
              </ul>
            </nav>
            <div className="border">
              <a href="https://imgur.com/JNes3J4">
                <img
                  src="https://i.imgur.com/JNes3J4.jpg"
                  alt="Hosted by imgur.com"
                />
              </a>
            </div>
          </header> */}

          <main className='mb-5 mt-5'>
            <article>
              <Container>
                <div className='case__section-title '>
                  <h2>
                    Partnering with a Leading UK College to Develop Immersive
                    Content for T Level Health and Science Programmes.
                  </h2>
                </div>
                <Row>
                  <Col>
                    <figure className='aside'>
                      {/* <div className='module'> */}
                      <ul>
                        <p>
                          <strong
                            className='text-black
                          '
                          >
                            {' '}
                            Client:
                          </strong>{' '}
                          A leading UK college offering T Levels in Health and
                          Science.
                        </p>

                        <p>
                          <strong
                            className='text-black
                          '
                          >
                            {' '}
                            Project:{' '}
                          </strong>{' '}
                          Provide students with immersive, real-world training
                          experiences
                        </p>
                        <p>
                          <strong
                            className='text-black
                          '
                          >
                            {' '}
                            Industry:{' '}
                          </strong>{' '}
                          Higher Education & Training
                        </p>
                        <p>
                          <strong
                            className='text-black
                          '
                          >
                            {' '}
                            Duration :{' '}
                          </strong>{' '}
                          Ongoing
                        </p>
                        <p>
                          <strong
                            className='text-black
                          '
                          >
                            {' '}
                            Technology:{' '}
                          </strong>{' '}
                          MR Hardware and capturing the real-world through a
                          series of cameras and sensors
                        </p>
                      </ul>
                      {/* </div> */}
                    </figure>
                  </Col>
                  <Col>
                    <div className='patelco'>
                      {/* <h1>Case Study:</h1> */}

                      {/* <h2>
                <i>for</i> &nbsp;Patelco Credit Union
              </h2> */}

                      <img src={imm} alt='immersive room' />
                    </div>
                  </Col>
                </Row>
              </Container>
              <Container>
                <h2 className='case__section-title '>
                  Solution: Developing Custom VR Scenarios for Health and
                  Science T Levels
                </h2>
                <p>
                  MXR partnered with the college to develop immersive training
                  scenarios specifically tailored to the T Level subjects in
                  Health and Science. Using the college’s existing immersive
                  room and VR headsets, MXR created bespoke content designed to
                  deliver practical, real-world experiences that complemented
                  the theoretical learning students received in the classroom.
                </p>
                <h4>Key Components of the Solution: </h4>
                <p>
                  {' '}
                  <strong className='text-black'>
                    1. Leveraging Existing Hardware :
                  </strong>
                  The college had invested in immersive technology, including a
                  state-of-the-art immersive room and VR headsets. MXR worked
                  closely with the college to understand their hardware
                  capabilities and maximise its use by developing content that
                  was compatible with the equipment..
                </p>
                <p>
                  {' '}
                  <strong className='text-black'>
                    {' '}
                    2. Custom Scenario Development :
                  </strong>
                  MXR developed a range of VR training scenarios that directly
                  aligned with the T Level curriculum in Health and Science.
                  These scenarios allowed students to engage in hands-on,
                  interactive experiences that would be difficult to replicate
                  in a traditional classroom setting. Key scenarios included:
                </p>
                <ul className='immersiveUl'>
                  <li>
                    <strong className='text-black'>
                      Health Sector Scenarios :{' '}
                    </strong>{' '}
                    VR simulations were developed to train students in roles
                    such as ambulance care assistants, healthcare assistants,
                    and dental nurses. These scenarios placed students in
                    real-world situations, such as providing patient care,
                    responding to emergencies, and assisting during medical
                    procedures. This allowed students to develop critical skills
                    like patient communication, emergency response, and
                    technical assistance in a safe, controlled environment.
                  </li>
                  <li>
                    <strong className='text-black'>
                      Healthcare Science Scenarios :{' '}
                    </strong>{' '}
                    For healthcare science roles such as radiography assistants
                    and sterile services technicians, MXR developed simulations
                    that allowed students to practice tasks like sterilising
                    surgical instruments, handling imaging equipment, and
                    adhering to hygiene protocols. These scenarios helped
                    students gain hands-on experience in highly technical and
                    regulated environments without the risks associated with
                    live medical equipment.
                  </li>
                  <li>
                    {' '}
                    <strong className='text-black'>
                      Science Sector Scenarios :
                    </strong>{' '}
                    Students studying science-related subjects, such as
                    laboratory technicians or food manufacturing inspectors,
                    were able to use the immersive room to conduct virtual
                    experiments, perform quality control inspections, and
                    collaborate on scientific projects. These VR experiences
                    helped students apply their theoretical knowledge in
                    practical settings, simulating the real-world tasks they
                    would encounter in the workforce.
                  </li>
                </ul>
                <p>
                  {' '}
                  <strong className='text-black'>
                    3. Gamification and Collective Training :{' '}
                  </strong>
                  3. Gamification and Collective Training : To increase student
                  engagement and collaboration, MXR incorporated gamification
                  into the scenarios. For example, students in healthcare roles
                  could compete to handle emergency care situations within a set
                  time, while science students could work together to solve
                  complex lab experiments, encouraging teamwork and
                  problem-solving skills.
                </p>
                <p>
                  {' '}
                  <strong className='text-black'>
                    {' '}
                    4. Scenario Management with InsightXR :
                  </strong>
                  MXR integrated the InsightXR platform to help the college
                  manage and deploy VR scenarios. InsightXR provided educators
                  with a user-friendly interface to launch training sessions,
                  monitor student progress, and access analytics on scenario
                  usage and learning outcomes. This enabled the college to track
                  student engagement and adjust training content as needed to
                  ensure maximum impact.
                </p>
              </Container>

              {/* <h4>Results: Maximising the Benefits of Immersive Technology</h4>
              <p>
                By partnering with MXR to develop custom content, the college
                was able to fully utilise its existing immersive hardware,
                resulting in significant improvements in the quality and
                engagement of its T Level Health and Science programmes:
              </p>

              <p>
                {' '}
                <strong className='text-black'>
                  Practical, Real-World Training :{' '}
                </strong>
                Students gained valuable hands-on experience through immersive
                scenarios, developing critical skills that prepared them for
                real-world roles in healthcare and science sectors. They were
                able to practice high-stakes tasks like emergency response and
                laboratory testing in a safe, controlled environment.
              </p>
              <p>
                {' '}
                <strong className='text-black'>
                  {' '}
                  Enhanced Student Engagement :{' '}
                </strong>
                The immersive nature of the VR content, combined with
                gamification elements, kept students highly engaged throughout
                their training. This led to higher participation and improved
                learning outcomes, with students noting that the scenarios
                brought theoretical lessons to life in a meaningful way.
              </p>
              <p>
                {' '}
                <strong className='text-black'>
                  {' '}
                  Efficient Use of Hardware Investment :{' '}
                </strong>
                The college successfully leveraged its Local Skills Improvement
                Funding by using the immersive technology to its full potential.
                The custom VR content ensured that the hardware investment
                translated into tangible educational benefits, offering students
                a cutting-edge learning experience.
              </p>
              <p>
                {' '}
                <strong className='text-black'>
                  {' '}
                  Improved Collaboration :{' '}
                </strong>
                The collective training elements within the immersive room
                encouraged teamwork and communication among students, preparing
                them for collaborative work environments in healthcare and
                science settings.
              </p> */}
              <Container>
                <h4>
                  Results: Maximising the Benefits of Immersive Technology
                </h4>
                <p>
                  By partnering with MXR to develop custom content, the college
                  was able to fully utilise its existing immersive hardware,
                  resulting in significant improvements in the quality and
                  engagement of its T Level Health and Science programmes:
                </p>

                <p>
                  <strong className='text-black'>
                    <FaHandsHelping
                      className='nhsIcons'
                      color='#1292ee'
                      size='3em'
                    />{' '}
                    Practical, Real-World Training :
                  </strong>
                  Students gained valuable hands-on experience through immersive
                  scenarios, developing critical skills that prepared them for
                  real-world roles in healthcare and science sectors. They were
                  able to practice high-stakes tasks like emergency response and
                  laboratory testing in a safe, controlled environment.
                </p>

                <p>
                  <strong className='text-black'>
                    <FaUserGraduate
                      className='nhsIcons'
                      color='#1292ee'
                      size='3em'
                    />{' '}
                    Enhanced Student Engagement :
                  </strong>
                  The immersive nature of the VR content, combined with
                  gamification elements, kept students highly engaged throughout
                  their training. This led to higher participation and improved
                  learning outcomes, with students noting that the scenarios
                  brought theoretical lessons to life in a meaningful way.
                </p>

                <p>
                  <strong className='text-black'>
                    <FaCogs className='nhsIcons' color='#1292ee' size='3em' />{' '}
                    Efficient Use of Hardware Investment :
                  </strong>
                  The college successfully leveraged its Local Skills
                  Improvement Funding by using the immersive technology to its
                  full potential. The custom VR content ensured that the
                  hardware investment translated into tangible educational
                  benefits, offering students a cutting-edge learning
                  experience.
                </p>

                <p>
                  <strong className='text-black'>
                    <FaUsers className='nhsIcons' color='#1292ee' size='3em' />{' '}
                    Improved Collaboration :
                  </strong>
                  The collective training elements within the immersive room
                  encouraged teamwork and communication among students,
                  preparing them for collaborative work environments in
                  healthcare and science settings.
                </p>
              </Container>
              <Container>
                <div className='con'>
                  <div className='row'>
                    <div className='column one third'>
                      {/* Optional space for image or left alignment */}
                    </div>
                    <div className='column two thirds'>
                      <figure className='text-center'>
                        <blockquote>
                          <p className='testimonial-text'>
                            <span
                              style={{
                                fontSize: '2rem',
                                fontStyle: 'italic',
                                color: '#1292ee',
                                lineHeight: '80px',
                              }}
                            >
                              &#10077;
                            </span>{' '}
                            MXR has been instrumental in helping us utilise our
                            immersive technology to its full potential. Their
                            custom-developed VR scenarios have allowed our
                            students to experience real-world situations in ways
                            that weren’t possible before. This partnership has
                            transformed our T Level programmes in Health and
                            Science, ensuring our students gain the practical
                            skills they need for success in their future
                            careers.
                            <span
                              style={{
                                fontSize: '2rem',
                                fontStyle: 'italic',
                                color: '#1292ee',
                                lineHeight: '80px',
                              }}
                            >
                              &#10078;
                            </span>
                          </p>
                        </blockquote>
                        <figcaption className='mt-2'>
                          <strong
                            style={{
                              color: '#1292ee',
                            }}
                          >
                            – Beth, Head of T Level Programmes
                          </strong>
                        </figcaption>
                      </figure>
                    </div>
                  </div>
                </div>
              </Container>
              {/* <div className='column one third bg-grey'>
                <p className='text-black'>
                "MXR has been instrumental in helping us utilise our immersive
                technology to its full potential. Their custom-developed VR
                scenarios have allowed our students to experience real-world
                  situations in ways that weren’t possible before. This
                  partnership has transformed our T Level programmes in Health
                  and Science, ensuring our students gain the practical skills
                  they need for success in their future careers.”
                </p>
                <h4>– Beth, Head of T Level Programmes</h4>
              </div> */}
              <Container>
                <div className='mxr__section'>
                  <h2 className='mxr__section case__section-title '>
                    Conclusion
                  </h2>
                  <p>
                    MXR’s partnership with the college highlights the
                    transformative power of immersive technology when aligned
                    with educational goals. By developing bespoke content for
                    the college’s existing immersive hardware, MXR ensured that
                    students in Health and Science T Level programmes could
                    benefit from realistic, hands-on training. The result was a
                    fully optimised immersive learning environment that not only
                    engaged students but also prepared them for the demands of
                    the workplace.
                  </p>
                  <p>
                    If your institution is looking to maximise the benefits of
                    your immersive technology investment, contact MXR today to
                    learn how we can create tailored VR content that aligns with
                    your educational needs.
                  </p>
                </div>
              </Container>
            </article>
          </main>
        </div>
      </Container>
      <Readytotalk discuss={readytotalk} />
    </>
  );
}

export default ImmCase;

import React, { useState } from 'react';
import { Container, Card } from 'react-bootstrap';
import Carousel from 'react-bootstrap/Carousel';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import icon1 from '../../assets/icons/slideIcons/enterprise.svg';
import icon2 from '../../assets/icons/slideIcons/Customisation.svg';
import icon3 from '../../assets/icons/slideIcons/ImmersiveTrain.svg';
import icon4 from '../../assets/icons/slideIcons/AdvancedAnalytics.svg';
import icon5 from '../../assets/icons/slideIcons/Scalability.svg';
import icon6 from '../../assets/icons/slideIcons/PerformanceAssessment.svg';
import icon7 from '../../assets/icons/slideIcons/ContentManagement.svg';
import icon8 from '../../assets/icons/slideIcons/VersatilityFlexibility.svg';
import icon9 from '../../assets/icons/slideIcons/Real-Time.svg';
import icon10 from '../../assets/icons/slideIcons/CostEfficiency.svg';
import icon11 from '../../assets/icons/slideIcons/Multi-XR.svg';
import icon12 from '../../assets/icons/slideIcons/DebriefingScenario.svg';
import icon13 from '../../assets/icons/slideIcons/RecordingUser.svg';
const iconStyle = { width: '80px' };

const features = [
  {
    no: '01',
    title: 'Enterprise Device Management',
    description:
      'Manage and remotely control VR/AR devices at scale, including app distribution, file management, and device health tracking.',
    icon: (
      <img src={icon1} alt='Enterprise Device Management' style={iconStyle} />
    ),
  },
  {
    no: '02',
    title: 'Customisation & User Experience',
    description:
      'Customize device experiences, such as locking devices to specific apps and tailoring home screens.\nEnsure smooth and engaging user experiences through optimised content delivery.',
    icon: (
      <img
        src={icon2}
        alt='Customisation & User Experience'
        style={iconStyle}
      />
    ),
  },
  {
    no: '03',
    title: 'Immersive Training & Simulations',
    description:
      'Provide high-fidelity, immersive VR training modules for industries like healthcare, construction, and manufacturing.\nSupport multi-user collaboration within the same virtual environment, enhancing teamwork.',
    icon: (
      <img
        src={icon3}
        alt='Immersive Training & Simulations'
        style={iconStyle}
      />
    ),
  },
  {
    no: '04',
    title: 'Advanced Analytics & Data Insights',
    description:
      'Capture and analyse spatial data to understand user interactions within 3D environments.\nProvide real-time analytics and performance tracking to offer deep insights and support immediate adjustments.',
    icon: (
      <img
        src={icon4}
        alt='Advanced Analytics & Data Insights'
        style={iconStyle}
      />
    ),
  },
  {
    no: '05',
    title: 'Scalability',
    description:
      'Support large-scale deployments across multiple locations, essential for managing thousands of devices or extensive training programs.',
    icon: <img src={icon5} alt='Scalability' style={iconStyle} />,
  },
  {
    no: '06',
    title: 'Performance Assessment & Skill Retention',
    description:
      'Track and assess user competency with detailed performance analytics, ensuring effective training outcomes and long-term skill retention.',
    icon: (
      <img
        src={icon6}
        alt='Performance Assessment & Skill Retention'
        style={iconStyle}
      />
    ),
  },
  {
    no: '07',
    title: 'Content Management & Delivery',
    description:
      'Create, distribute, and manage VR/AR content across various devices, enabling organisations to deploy interactive experiences efficiently.',
    icon: (
      <img src={icon7} alt='Content Management & Delivery' style={iconStyle} />
    ),
  },
  {
    no: '08',
    title: 'Versatility & Flexibility',
    description:
      'Offer versatile applications across industries, from education and healthcare to marketing and high-risk job training.',
    icon: <img src={icon8} alt='Versatility & Flexibility' style={iconStyle} />,
  },
  {
    no: '09',
    title: 'Real-Time Command Execution',
    description:
      'Send real-time commands and updates to devices, enhancing responsiveness and operational efficiency.',
    icon: (
      <img src={icon9} alt='Real-Time Command Execution' style={iconStyle} />
    ),
  },
  {
    no: '10',
    title: 'Cost Efficiency',
    description:
      'Reduce training and operational costs by leveraging scalable, virtual simulation environments instead of traditional methods.',
    icon: <img src={icon10} alt='Cost Efficiency' style={iconStyle} />,
  },
  {
    no: '11',
    title: 'Multi-XR Device Casting',
    description:
      'Enable the casting of multiple XR devices to a single screen, facilitating collaborative viewing and easier demonstration or review of XR experiences.',
    icon: <img src={icon11} alt='Multi-XR Device Casting' style={iconStyle} />,
  },
  {
    no: '12',
    title: 'Debriefing Scenario',
    description:
      'Include debriefing tools to facilitate post-simulation discussions, allowing participants to review their actions, understand their decisions, and reinforce learning outcome.',
    icon: <img src={icon12} alt='Debriefing Scenario' style={iconStyle} />,
  },
  {
    no: '13',
    title: 'Recording User Interactions',
    description:
      'Ability to record user interactions within scenarios, allowing for detailed review and analysis of user behaviour, decisions, and learning outcomes.',
    icon: (
      <img src={icon13} alt='Recording User Interactions' style={iconStyle} />
    ),
  },
];

const Slide = () => {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  const chunkedFeatures = [];
  for (let i = 0; i < features.length; i += 4) {
    chunkedFeatures.push(features.slice(i, i + 4));
  }

  return (
    <div className='mxr__research position-relative'>
      <Container>
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          prevIcon={
            <FaChevronLeft size={40} className='text-primary leftright' />
          }
          nextIcon={
            <FaChevronRight size={40} className='text-primary leftright' />
          }
          className='custom-carousel'
        >
          {chunkedFeatures.map((chunk, chunkIndex) => (
            <Carousel.Item
              key={chunkIndex}
              className='mt-5 mb-5 custom-carousel-item'
            >
              <div className='d-flex justify-content-between '>
                {chunk.map((feature, featureIndex) => (
                  <Card key={feature.no} className='slideCard mx-2'>
                    <Card.Body className='d-flex flex-column'>
                      <Card.Title style={{ fontSize: '50px' }}>
                        {feature.no}
                      </Card.Title>
                      <Card.Subtitle className='mb-2 text-muted'>
                        <h4>{feature.title}</h4>
                      </Card.Subtitle>
                      <Card.Text className='flex-grow-1 slideCardPara'>
                        {feature.description}
                      </Card.Text>
                      <div className='text-center mt-auto'>{feature.icon}</div>
                    </Card.Body>
                  </Card>
                ))}
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
      </Container>
    </div>
  );
};

export default Slide;

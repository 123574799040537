import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
// import Card from 'react-bootstrap/Card';

const Custom = () => {
  return (
    <div className=' mxr__section ' style={{ backgroundColor: '#f8faff' }}>
      <Container>
        <h3
          className='mxr__section-title textUppercase'
          style={{ textAlign: 'center' }}
        >
          Custom vs. Off-The-Shelf Contents
        </h3>

        <Row className='mt-5'>
          <Col>
            <div className='vscards VsBottom'>
              <h5 className='textUppercase'>Custom VR Training Content</h5>
              <p style={{ textAlign: 'start' }}>
                Custom VR content allows organisations to tailor the training to
                their specific needs, providing greater control and relevance.
                Custom content can be designed to simulate particular scenarios,
                offering a unique and engaging learning experience that meets
                the precise requirements of your team.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Tailored to specific training needs</li>
                <li>Highly engaging and relevant</li>
                <li>Flexible and updatable</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className='vscards'>
              <h5 className='textUppercase'>
                Off-The-Shelf VR Training Content
              </h5>
              <p style={{ textAlign: 'start' }}>
                Off-the-shelf VR content offers ready-made training solutions
                that can quickly be deployed to meet foundational training
                needs. These pre-designed programmes are cost-effective and
                convenient, providing essential training without the need for
                extensive customisation.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Cost-effective and ready to use</li>
                <li>Ideal for general training needs</li>
                <li>Quick deployment</li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Custom;

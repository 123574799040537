import React from 'react';
import Capabilities from './Capabilities';
import WhatWeCanDo from './WhatWeCanDo';
import { Innerbanner, Readytotalk } from '../../components';
import { Helmet } from 'react-helmet';
import CustomVsOff from './CustomVSoff';
import About from './About';
import ArStatistics from './ArStat';
// import ArBanner from './ArBanner';

function AR() {
  const bannerdata = {
    img: 'Angumented-Reality3.png',
    title: 'AUGMENTED REALITY APP DEVELOPMENT',
    text: 'Take your company to a new level of market supremacy with our outstanding AR development services',
  };

  const readytotalk = {
    img: 'MR.jpg',
    title: 'Contact Us',
    text: 'Partner with MXR to design custom AI solutions that seamlessly integrate with your AR applications. Enhance user experiences, improve interactivity, and push the boundaries of what is possible. Elevate your AR projects with MXR today!',
    button: 'Get in Touch',
  };
  return (
    <div>
      <Helmet>
        <title>AUGMENTED REALITY APP DEVELOPMENT | MXR</title>
        <meta
          name='description'
          content='MXR is UK based software development company delivering reliable and in-budget solutions for AR, VR, MR, Web and Mobile App development to clients globally.'
        />
      </Helmet>
      {/* <ArBanner /> */}

      <Innerbanner banner={bannerdata} />
      <ArStatistics />
      <About />
      <WhatWeCanDo />
      <Capabilities />
      <CustomVsOff />
      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default AR;

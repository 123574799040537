import React from 'react';
import { Container, Row, Col } from 'react-bootstrap-v5';
// import Card from 'react-bootstrap/Card';

const CustomVsOff = () => {
  return (
    <div className=' mxr__section  bg-light arSection'>
      <Container>
        <h3
          className='textUppercase mxr__section-title '
          style={{ textAlign: 'center' }}
        >
          Custom vs. Off-The-Shelf Solutions
        </h3>

        <Row className='mt-5'>
          <Col>
            <div className='vscards VsBottom'>
              <h5 className='textUppercase mb-4'>Custom AR Training Content</h5>
              <p style={{ textAlign: 'start' }}>
                Custom AR content allows organisations to tailor the training to
                their specific needs, providing greater control and relevance.
                Custom content can be designed to simulate particular scenarios,
                offering a unique and engaging learning experience that meets
                the precise requirements of your team.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Tailored to specific training needs</li>
                <li>Highly engaging and relevant</li>
                <li>Flexible and updatable</li>
              </ul>
            </div>
          </Col>
          <Col>
            <div className='vscards'>
              <h5 className='textUppercase mb-4'>
                Off-The-Shelf AR Training Content
              </h5>
              <p style={{ textAlign: 'start' }}>
                Off-the-shelf AR content offers ready-made training solutions
                that can quickly be deployed to meet foundational training
                needs. These pre-designed programmes are cost-effective and
                convenient, providing essential training without the need for
                extensive customisation.
              </p>
              <p
                className='textUppercase'
                style={{
                  textAlign: 'start',
                  color: '#1292ee',
                  fontWeight: 'bold',
                }}
              >
                Benefits:
              </p>
              <ul className='benefits' style={{ color: '#777' }}>
                <li>Cost-effective and ready to use</li>
                <li>Quick deployment</li>
                <li>Ideal for general training needs</li>
              </ul>
            </div>
          </Col>
        </Row>

        {/* <Row className="mt-5 justify-content-md-center">
          <Col sm={8} className="text-center">
            <h3>
              How Much Does 360 Virtual Tour Development Cost?
            </h3>
            <p>
              Costs vary based on customisation and complexity. Custom content
              typically ranges from £10,000 to £50,000 or more. We work closely
              with you to determine the most effective and budget-friendly
              approach.
            </p>
          </Col>
          </Row> */}
      </Container>
    </div>
  );
};

export default CustomVsOff;

import React from 'react';
import { Container, Col, Row } from 'react-bootstrap-v5';
import exam2 from '../../assets/images/exam2.png';
import Exp from '../../assets/images/Exp1.svg';
import Sol from '../../assets/images/Sol1.svg';
import Inn from '../../assets/images/Inn1.svg';
import Sup from '../../assets/images/Sup1.svg';
import Cos from '../../assets/images/Cos1.svg';

const Why = () => {
  return (
    <div
      className='mxr__capabilities mxr__section white_bg_point'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container>
        <h2 className='mxr__section-title textUppercase'>
          Why Choose ExamWizz?
        </h2>

        <Row>
          <Col className='immersiveImg' sm={8}>
            <div>
              <ul>
                <li className='abouticon'>
                  <img src={Exp} alt='Icon' />
                  <div>
                    <h5>Personalised Learning Experience</h5>
                    <p>
                      Tailored study plans and adaptive learning modules that
                      match each learner’s pace and style.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={Sol} alt='Icon' />
                  <div>
                    <h5>Comprehensive Course Content</h5>
                    <p>
                      Access a vast library of study materials, video lectures,
                      and practice tests across multiple subjects and
                      competitive exams.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={Inn} alt='Icon' />
                  <div>
                    <h5>Interactive Assessments and Analytics</h5>
                    <p>
                      Get detailed performance insights with our smart analytics
                      dashboard, helping students focus on areas that need
                      improvement.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={Sup} alt='Icon' />
                  <div>
                    <h5>AI-Powered Recommendations</h5>
                    <p>
                      Leverage AI to receive suggestions on resources, practice
                      questions, and study techniques based on your progress.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={Cos} alt='Icon' />
                  <div>
                    <h5>Seamless User Experience</h5>
                    <p>
                      Enjoy an intuitive and user-friendly interface, making
                      learning enjoyable whether you’re on a desktop or mobile
                      device.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={4}>
            <img
              src={exam2}
              alt='Why choose us'
              style={{ width: '600px', borderRadius: '20px' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Why;

import React from 'react';
import { Row, Container, Col } from 'react-bootstrap-v5';
import one from '../../assets/images/enter1.svg';
import two from '../../assets/images/enter2.svg';
import three from '../../assets/images/enter3.svg';
import four from '../../assets/images/enter4.svg';
import five from '../../assets/images/enter5.svg';
import six from '../../assets/images/enter6.svg';
import parternshipImg2 from '../../assets/images/partnership3.png';

const SecretSauce = () => {
  return (
    <div
      className='mxr__capabilities mxr__section white_bg_point Aboutwhatwemargin'
      style={{ backgroundColor: '#f8faff' }}
    >
      <Container>
        <h2 className='mxr__section-title textUppercase'>
          Why Enterprises Opt InsightXR
        </h2>
        <p>
          Tailored for enterprises, our platform provides comprehensive
          security, customisable user experiences, and streamlined management of
          XR devices.
        </p>
        <Row className='mt-5'>
          <Col sm={8}>
            <div>
              <ul>
                <li className='abouticon'>
                  <img src={one} alt='Icon' />
                  <div>
                    <h5>Enhancing Efficiency in Deployment</h5>
                    <p>
                      Efficiently manage numerous devices and content from a
                      centralised location.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={two} alt='Icon' />
                  <div>
                    <h5>Enhancing the Employee Experience</h5>
                    <p>
                      Implement kiosk mode and a customised home screen to
                      ensure a secure and focused work environment.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={three} alt='Icon' />
                  <div>
                    <h5>Superior Security and Device Management</h5>
                    <p>
                      Gain command with our advanced security features and
                      robust device management capabilities.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={four} alt='Icon' />
                  <div>
                    <h5>Data-Driven 3D Analytics with InsightXR</h5>
                    <p>
                      Harness our analytical tools to support data-driven
                      decisions and successful scaling.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={five} alt='Icon' />
                  <div>
                    <h5>Flexible Content Integration</h5>
                    <p>
                      Bring your own content and utilise our Content Partner
                      Network for access to premier XR applications.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={six} alt='Icon' />
                  <div>
                    <h5>Streamlined Deployment Process</h5>
                    <p>
                      Utilise your existing global technology reseller partners
                      for convenient procurement and management of hardware and
                      content.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={4} style={{ alignContent: 'center' }}>
            <img
              className='aboutwhatwedoImg'
              src={parternshipImg2}
              alt='Why choose us'
              style={{ height: '500px' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SecretSauce;

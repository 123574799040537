import React from 'react';
// import images from '../../assets/icons/benifits/benifits.png';
import { Helmet } from 'react-helmet';
import { Innerbanner, Readytotalk } from '../../components';
import About from './About';
import development from '../../assets/images/Platform1.png';
import Feature from './Feature';
import Solution from './Solution';
import Compare from './Compare';
import Right from './Right';

const dataList = [
  {
    id: '1',
    heading: 'Platform Development',
    images: <img src={development} alt='Platform Development' />,
    pharagraphs: [
      "In today's rapidly evolving technological landscape, having the right platforms is crucial for any organisation aiming to stay competitive. At MXR, we specialise in designing and developing custom platforms that seamlessly integrate with various systems to meet your unique business needs.",
    ],
  },
];

// const workdata = [
//   {
//     id: '1',
//     heading: 'How Does It Work?',
//     images: (
//       <img src={images} alt='benifits of vr 360' style={{ height: '80px' }} />
//     ),
//     pharagraphs: [
//       'Creating a 360 Virtual Tour is simple: upload your 360/VR photos, edit and enhance them with interactive elements, and share your tours easily. Users can navigate through the space, interact with various elements, and get a realistic feel of the environment.',
//     ],
//   },
// ];

const bannerdata = {
  img: 'platform-development.jpg',
  //   img: "vr-360-banner.jpg",
  title: 'Platform Development',
  //   text: "Create, edit, share. Upload 360/VR photos. Easily create virtual tours. Share anywhere!",
};

const readytotalk = {
  img: 'MR.jpg',
  title: 'Contact Us',
  // text: 'Ready to explore how AI can transform your business or educational environment? Partner with MXR today to develop custom AI solutions that integrate seamlessly with your existing technology.',
  button: 'Get in Touch',
};
function Platform() {
  return (
    <div>
      <Helmet>
        <title>360 VIRTUAL TOURS | VR 360 | MXR</title>
        <meta
          name='description'
          content='360° Virtual Reality (VR) Tours became a perfect marketing tool in real estate and design. It is particularly useful for those projects that are still in the development stage or under construction.'
        />
      </Helmet>
      <Innerbanner banner={bannerdata} />
      <About banner={dataList} />
      <Solution />
      <Compare />
      <Feature />
      <Right />
      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default Platform;

import React from 'react';
import { Row, Container, Col } from 'react-bootstrap-v5';
import con from '../../assets/images/PlatformD.svg';
import ai from '../../assets/images/aidev3.svg';
import imm from '../../assets/images/Immersive-Solutions.svg';
import web from '../../assets/images/webapp.svg';
import parternshipImg2 from '../../assets/images/partnership3.png';

const SecretSauce = () => {
  return (
    <div className='mxr__capabilities mxr__section white_bg_point Aboutwhatwemargin'>
      <Container>
        <h2 className='mxr__section-title textUppercase'>What We Do</h2>

        <Row>
          <Col sm={8}>
            <div>
              <ul>
                <li className='abouticon'>
                  <img src={imm} alt='Icon' />
                  <div>
                    <h5>Immersive Solutions</h5>
                    <p>
                      We develop AR, VR, and Mixed Reality experiences that make
                      learning and training more interactive, effective, and
                      engaging, ensuring your team gets the most out of each
                      session.
                    </p>
                  </div>
                </li>

                <li className='abouticon'>
                  <img src={con} alt='Icon' />
                  <div>
                    <h5>Platform & Content Development</h5>
                    <p>
                      We build flexible platforms for LMS, workforce planning,
                      and data analytics that seamlessly integrate with your
                      systems, enhancing user engagement and operational
                      efficiency.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={web} alt='Icon' />
                  <div>
                    <h5>Website & App Development</h5>
                    <p>
                      Our team designs scalable websites and mobile applications
                      to align with your digital strategy, helping you enhance
                      your online presence and improve user engagement.
                    </p>
                  </div>
                </li>
                <li className='abouticon'>
                  <img src={ai} alt='Icon' />
                  <div>
                    <h5>Generative AI</h5>
                    <p>
                      Our AI solutions are designed to support your specific
                      needs, providing intelligent and interactive experiences
                      for education, training, and business applications. Our
                      AI-driven tools enhance user engagement, offering
                      real-time support tailored to your content.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
          <Col sm={4}>
            <img
              className='aboutwhatwedoImg'
              src={parternshipImg2}
              alt='Why choose us'
              style={{ height: '500px' }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SecretSauce;

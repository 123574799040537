import React from 'react';
// import Benifits from '../vr360/Benifits';
import images from '../../assets/icons/benifits/36044.jpg';
import Advance from './Advance';
import Advance2 from './Advance2';
import Pictures from './Pictures';
import WhyChoose360 from './WhyChoose360';
// import Cost from './HowItWork';
import { Readytotalk } from '../../components';
// import Benefits from './Benifits';
// import TechnologyWork from '../vr/TechnologyWork';
import { Helmet } from 'react-helmet';
import About from './About';
// import HowWork from './HowWork';
import CustomVsOffShelf from './CustomVsOffShelf';
import BenefitCard from './BenifitCard';
import './vr360about.css';
import AllBanner from '../../components/BannerVideo/BannerVideo';

const dataList = [
  {
    id: '1',
    heading: 'What is 360 Virtual Tours?',
    images: <img src={images} alt='benifits of vr 360' />,
    pharagraphs: [
      '360 Virtual Tours provide an immersive way for users to explore spaces interactively. Upload 360/VR photos, add interactive hotspots, and create tours that can be shared anywhere. Perfect for real estate, automotive, education, and more, these tours offer a dynamic way to present spaces and engage users.',
    ],
  },
];

// const bannerdata = {
//   img: '360-image2.jpg',
//   title: '360 VIRTUAL TOURS MADE EASY.',
//   text: 'Create, edit, share. Upload 360/VR photos. Easily create virtual tours. Share anywhere!',
// };
const banner = [
  {
    video: '360video2.mp4',
    thumbnail: '360.jpg',
    heading: '360 VIRTUAL TOURS MADE EASY.',
    subHeading:
      'Create, edit, share. Upload 360/VR photos. Easily create virtual tours. Share anywhere!',
  },
];

const readytotalk = {
  img: 'ready.jpg',
  title: 'Partner with us',
  text: 'At MXR, we offer powerful, engaging, and effective 360 Virtual Tours. Partner with us to create immersive virtual tours that transform your business.',
  button: "LET'S DO IT",
};
function VR() {
  return (
    <div>
      <Helmet>
        <title>360 VIRTUAL TOURS | VR 360 | MXR</title>
        <meta
          name='description'
          content='360° Virtual Reality (VR) Tours became a perfect marketing tool in real estate and design. It is particularly useful for those projects that are still in the development stage or under construction.'
        />
      </Helmet>
      {/* <Innerbanner banner={bannerdata} /> */}
      <AllBanner banners={banner} />
      <About banner={dataList} />
      {/* <HowWork data={workdata}/> */}
      {/* <Benifits data={dataList} /> */}
      <Advance />
      <Advance2 />
      <WhyChoose360 />
      <Pictures />
      <BenefitCard />
      <CustomVsOffShelf />
      {/* <Cost data={workdata} /> */}
      <Readytotalk discuss={readytotalk} />
    </div>
  );
}

export default VR;

import React, { useState } from 'react';
import './readytotalk.css';
import { Container, Row, Col } from 'react-bootstrap-v5';
import styled from 'styled-components';
import MeetingScheduler from '../../components/FormSceduler';

const Outerdiv = styled.div`
  background-image: url('${require(`../../assets/icons/readytotalk/ready.jpg`)}');
`;

const Readytotalk = (props) => {
  const [connect, setConnect] = useState('');

  return (
    <div id='readytotalk' className='mxr__readytotalk mxr__section'>
      <Outerdiv banner={props.discuss.img}>
        <Container>
          <Row>
            <Col lg={12} md={12} className='ready'>
              <div className='ready_content '>
                <h2 className='textUppercase'>{props?.discuss?.title}</h2>
                <p>{props?.discuss?.text}</p>
                {!connect && (
                  <button
                    className='mxr__section-btn'
                    onClick={() => {
                      setConnect(1);
                    }}
                  >
                    {props?.discuss?.button}
                  </button>
                )}
                {connect && (
                  <div>
                    <MeetingScheduler />
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Outerdiv>
    </div>
  );
};

export default Readytotalk;

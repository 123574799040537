import React from 'react';
import './Casestudy.css';
import { Container, Row, Col, Card } from 'react-bootstrap-v5';
import {
  FaBrain,
  FaBook,
  FaPencilAlt,
  FaBuilding,
  FaCheck,
} from 'react-icons/fa';
import { Innerbanner } from '../../components';
import Readytotalk from '../../components/readytotalk/Readytotalk';
// import cas from '../../assets/All-Images/case.png';
import bot from '../../assets/images/bot3.png';

const readytotalk = {
  img: 'MR.jpg',
  title: 'Get in Touch',
  text: 'Contact us today to learn more about how MXR can partner with your institution to revolutionise the way you deliver education.',
  button: 'Contact us',
};
function Casestudy() {
  const bannerdata = {
    img: 'AiChatCaseBanner.jpg',
    title: 'Chat Bot Case Study',
    text: '',
  };
  return (
    <>
      <Innerbanner banner={bannerdata} />
      <Container>
        <div className='gutters'>
          {/* <header className="page-header group">
            <div className="logo">
              <h1>James L Cobb</h1>
              <h2>User Experience Designer</h2>
            </div>
            <nav className="main-nav">
              <ul>
                <li className="active">
                  <a href="#">Work</a>
                </li>
                <li>
                  <a href="#">About</a>
                </li>
              </ul>
            </nav>
            <div className="border">
              <a href="https://imgur.com/JNes3J4">
                <img
                  src="https://i.imgur.com/JNes3J4.jpg"
                  alt="Hosted by imgur.com"
                />
              </a>
            </div>
          </header> */}

          <main className='mb-5 mt-5'>
            <article>
              <Container>
                <Row>
                  <Col>
                    <div className='case__section-title'>
                      <h2>
                        Enhancing the Learner Journey for APM Project Management
                        Qualification with AI-Powered Chatbot
                      </h2>
                    </div>
                    <figure className='aside chatbotCase'>
                      {/* <div className='module'> */}
                      <ul>
                        <p>
                          <strong className='text-black'> Client : </strong>APM
                          Authorised Training Provider
                        </p>
                        <p>
                          <strong className='text-black'> Project : </strong>
                          Development of AI Chatbot for APM Project Management
                          Qualification
                        </p>
                        <p>
                          <strong className='text-black'> Industry : </strong>
                          Education & Training
                        </p>
                        <p>
                          <strong className='text-black'>Duration : </strong> 3
                          Months
                        </p>
                        <p>
                          <strong className='text-black'>Technology : </strong>
                          Generative AI, Natural Language Processing (NLP),
                          Learning Management System (LMS) Integration
                        </p>
                      </ul>
                      {/* </div> */}
                    </figure>
                  </Col>
                  <Col>
                    <div className='patelco'>
                      {/* <h1>Case Study:</h1> */}

                      {/* <h2>
                <i>for</i> &nbsp;Patelco Credit Union
              </h2> */}

                      <img src={bot} alt='bot' />
                    </div>
                  </Col>
                </Row>
              </Container>
              <h2 className='case__section-title '>Background</h2>
              <p>
                The APM Project Management Qualification (PMQ) is one of the
                most challenging certifications in the field of project
                management. It requires in-depth knowledge of methodologies,
                processes, and best practices outlined in the APM Body of
                Knowledge. For many students, mastering the extensive syllabus
                and passing the rigorous 3.5-hour exam can be daunting.
              </p>
              <p>
                An authorised training provider for the APM qualifications
                approached MXR with a clear objective: to enhance their learner
                journey by integrating AI into their newly acquired Learning
                Management System (LMS). The goal was to improve student
                engagement, support, and ultimately, their success rates in the
                APM PMQ exam.
              </p>
              <h2 className='case__section-title'>Challenge</h2>
              <p>The training provider faced several challenges :</p>
              <p>
                <FaCheck size={18} color='#1292ee' className='me-2 nhsIcons' />
                <strong className='text-black'>Student Support :</strong> The
                complexity of the APM syllabus meant that students often
                required additional guidance outside of classroom hours.
              </p>
              <p>
                <FaCheck size={18} color='#1292ee' className='me-2 nhsIcons' />
                <strong className='text-black'>Content Mastery :</strong>{' '}
                Students struggled with retaining and applying the vast amount
                of information required to pass the PMQ exam.
              </p>
              <p>
                <FaCheck size={18} color='#1292ee' className='me-2 nhsIcons' />
                <strong className='text-black'>
                  Resource Limitations :
                </strong>{' '}
                The training provider needed a solution that could offer
                scalable, consistent, and accurate support without overburdening
                their instructors.
              </p>
              <p>
                <FaCheck size={18} color='#1292ee' className='me-2 nhsIcons' />
                <strong className='text-black'>Engagement:</strong> Traditional
                learning methods were not fully engaging students, leading to
                lower pass rates and increased stress.
              </p>
              <h2 className='case__section-title'>Solution</h2>
              <p>
                MXR proposed the development of an AI-powered chatbot
                specifically trained to assist students in navigating the APM
                PMQ syllabus. This innovative tool was designed to:
              </p>
              <div className='pictures'>
                <figure className='full'>
                  {/* <img
      src='https://i.imgur.com/67V2iAe.jpg'
      alt='Team at work'
    /> */}
                  {/* <figcaption>Our team hard at work</figcaption> */}
                </figure>
              </div>
              <figure className='aside1'>
                <div className='two'>
                  <p>
                    <FaCheck
                      size={18}
                      color='#1292ee'
                      className='me-2 nhsIcons'
                    />
                    <strong className='text-black'>
                      Understand the APM PMQ Syllabus:{' '}
                    </strong>
                    The chatbot was trained on the full syllabus by scanning
                    APM's official web pages and related resources. It was also
                    fed with mock exams from the training provider and other
                    credible online sources.
                  </p>
                  <p>
                    <FaCheck
                      size={18}
                      color='#1292ee'
                      className='me-2 nhsIcons'
                    />
                    <strong className='text-black'>
                      Provide Instant Answers:{' '}
                    </strong>
                    Students could ask the chatbot questions about project
                    management processes, methodologies, or specific terms
                    within the syllabus. The chatbot would immediately provide
                    relevant information, examples, and direct students to the
                    exact location in the syllabus where they could find more
                    details.
                  </p>
                  <p>
                    <FaCheck
                      size={18}
                      color='#1292ee'
                      className='me-2 nhsIcons'
                    />
                    <strong className='text-black'>
                      Offer Practice Exams:{' '}
                    </strong>
                    The chatbot could generate a bank of mock exam questions
                    tailored to the student's needs. After the student completed
                    a mock exam, they could upload their answers back to the
                    chatbot for evaluation.
                  </p>
                  <p>
                    <FaCheck
                      size={18}
                      color='#1292ee'
                      className='me-2 nhsIcons'
                    />{' '}
                    <strong className='text-black'>
                      Detailed Feedback and Guidance:{' '}
                    </strong>
                    The chatbot would mark the exams and offer personalised
                    feedback, advising students on how to improve their answers
                    and where to refer in the syllabus for further study.
                  </p>
                </div>
              </figure>
              <p>
                From our research, we found most users had a neutral reaction to
                online banking. But those who used Simple or USAA happily &
                positively described their banking experience. This directed us
                to look at these apps & tease out what they had that other
                banking apps lacked. What we found is that most apps had a
                neutral, cold tone to them; Simple & USAA both had an
                approachable, clear, guiding voice to their copy and features.
                This gave the user a sense of control over how they interacted
                with their bank.
              </p>
              <Container className='my-5'>
                <h2 className='case__section-title '>Key Features</h2>

                <Row className='mt-4'>
                  <Col md={6} className='mb-4'>
                    <Card className='text-center h-100'>
                      <Card.Body className='d-flex flex-column '>
                        <FaBrain
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                          style={{ margin: '0 auto' }}
                        />{' '}
                        {/* Icon for NLP */}
                        <Card.Title className='mt-3'>
                          Natural Language Processing (NLP)
                        </Card.Title>
                        <Card.Text className='flex-grow-1'>
                          Enabled the chatbot to understand and respond to
                          student queries in a conversational manner.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6} className='mb-4'>
                    <Card className='text-center h-100'>
                      <Card.Body className='d-flex flex-column'>
                        <FaBook
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                          style={{ margin: '0 auto' }}
                        />{' '}
                        {/* Icon for Syllabus Mapping */}
                        <Card.Title className='mt-3'>
                          Syllabus Mapping
                        </Card.Title>
                        <Card.Text className='flex-grow-1'>
                          Direct links to relevant sections in the APM syllabus,
                          providing contextually accurate information.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6} className='mb-4'>
                    <Card className='text-center h-100'>
                      <Card.Body className='d-flex flex-column'>
                        <FaPencilAlt
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                          style={{ margin: '0 auto' }}
                        />{' '}
                        {/* Icon for Mock Exam Integration */}
                        <Card.Title className='mt-3'>
                          Mock Exam Integration
                        </Card.Title>
                        <Card.Text className='flex-grow-1'>
                          Automated generation and marking of mock exams with
                          feedback loops.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6} className='mb-4'>
                    <Card className='text-center h-100'>
                      <Card.Body className='d-flex flex-column'>
                        <FaBuilding
                          className='nhsIcons'
                          size={50}
                          color='#1292ee'
                          style={{ margin: '0 auto' }}
                        />{' '}
                        {/* Icon for White-Labelled Solution */}
                        <Card.Title className='mt-3'>
                          White-Labelled Solution
                        </Card.Title>
                        <Card.Text className='flex-grow-1'>
                          The chatbot was branded with the training provider's
                          identity, offering a seamless experience for students.
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              </Container>
              <h2 className='case__section-title implementationMargin '>
                {' '}
                Implementation
              </h2>
              <p>
                The development process involved close collaboration between
                MXR’s AI development team and the training provider. The project
                was executed in the following phases:
              </p>
              <p>
                <strong className='text-black'>
                  1. Requirement Analysis :{' '}
                </strong>{' '}
                Understanding the training provider's needs, the complexity of
                the APM syllabus, and the LMS capabilities.
              </p>
              <p>
                <strong className='text-black'>2. AI Training :</strong> Feeding
                the chatbot with extensive data from the APM syllabus, mock
                exams, and industry best practices.
              </p>
              <p>
                <strong className='text-black'>3. LMS Integration :</strong>
                Seamless integration of the chatbot into the existing LMS,
                ensuring a smooth user experience.
              </p>
              <p>
                <strong className='text-black'>
                  {' '}
                  4. Testing and Iteration :
                </strong>{' '}
                Rigorous testing with real student queries and exam scenarios to
                refine the chatbot’s accuracy and response times.
              </p>
              <p>
                <strong className='text-black'> 5. Deployment :</strong> Rolling
                out the chatbot to the training provider’s students and
                providing ongoing support.
              </p>
              <h2 className='case__section-title  mt-3'> Results</h2>
              <p>
                <strong className='text-black'>• Improved Pass Rates : </strong>{' '}
                Students using the chatbot demonstrated a higher pass rate on
                the APM PMQ exam compared to those who relied solely on
                traditional study methods.
              </p>
              <p>
                <strong className='text-black'> • Enhanced Engagement :</strong>{' '}
                The interactive and instant nature of the chatbot increased
                student engagement and confidence in preparing for the exam.
              </p>
              <p>
                <strong className='text-black'>• Resource Efficiency : </strong>{' '}
                The training provider could scale support to all students
                without additional strain on their instructors.
              </p>
              <p>
                <strong className='text-black'>
                  • Positive Student Feedback :{' '}
                </strong>
                Students appreciated the 24/7 availability of the chatbot and
                the personalised feedback it provided, which was crucial in
                their exam preparation.
              </p>
              {/* <div className='con'>
                <div className='row'>
                  <div className='column one third'>
                    <figure>
                     
                    </figure>
                  </div>
                  <p className='text-black'>
                    "The AI chatbot developed by MXR has been a game-changer for
                    our students preparing for the APM PMQ. It provides the
                    support they need, exactly when they need it, and has
                    undoubtedly contributed to our improved pass rates. The
                    seamless integration into our LMS and the personalised
                    experience it offers make it an invaluable tool for both our
                    learners and instructors."
                  </p>
                  <div className='column one third'>
                    <figure>
                      <figcaption>
                        <strong>
                          — Training Provider, APM Qualifications{' '}
                        </strong>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div> */}
              <div className='con'>
                <div className='row'>
                  <div className='column one third'>
                    {/* Optional space for image or left alignment */}
                  </div>
                  <div className='column two thirds'>
                    <figure className='text-center'>
                      <blockquote>
                        <p className='testimonial-text'>
                          <span
                            style={{
                              fontSize: '2rem',
                              fontStyle: 'italic',
                              color: '#1292ee',
                              lineHeight: '78px',
                            }}
                          >
                            &#10077;
                          </span>{' '}
                          The AI chatbot developed by MXR has been a
                          game-changer for our students preparing for the APM
                          PMQ. It provides the support they need, exactly when
                          they need it, and has undoubtedly contributed to our
                          improved pass rates. The seamless integration into our
                          LMS and the personalised experience it offers make it
                          an invaluable tool for both our learners and
                          instructors.
                          <span
                            style={{
                              fontSize: '2rem',
                              fontStyle: 'italic',
                              color: '#1292ee',
                              lineHeight: '78px',
                            }}
                          >
                            &#10078;
                          </span>
                        </p>
                      </blockquote>
                      <figcaption className='mt-2'>
                        <strong
                          style={{
                            color: '#1292ee',
                          }}
                        >
                          — Training Provider, APM Qualifications
                        </strong>
                      </figcaption>
                    </figure>
                  </div>
                </div>
              </div>
              <h2 className='case__section-title  mt-3'>
                {' '}
                Future Applications
              </h2>
              <p>
                The success of this project highlights the potential for similar
                AI solutions to be applied across other qualifications and
                industries. MXR is now positioned to offer this white-labelled,
                customisable chatbot solution to other training providers and
                awarding bodies, helping them enhance the learning journey for
                their students.
              </p>
            </article>
          </main>
        </div>
      </Container>
      <Readytotalk discuss={readytotalk} />
    </>
  );
}

export default Casestudy;
